import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner02.png'
import img01 from '../../assets/img/Recruit/Recruit00.png'
import img02 from '../../assets/img/Recruit01/Recruit01_02.png'
import btn01 from '../../assets/img/Recruit/btn01.png'
import btn02 from '../../assets/img/Recruit/btn02.png'
import btn03 from '../../assets/img/Recruit/btn03.png'
import btn04 from '../../assets/img/Recruit/btn04.png'
import btn05 from '../../assets/img/Recruit/btn05.png'
import btn06 from '../../assets/img/Recruit/btn06.png'
import btn07 from '../../assets/img/Recruit/btn07.png'
import square from '../../assets/img/Work01/square.png'
import btn from '../../assets/img/Work01/work01btn.png'
import imgCom from '../../assets/img/Recruit/RecruitCom.png'

function Work01contents() {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
    divStyle: {
      height: 'auto',
      width: '100%',
    },
    divBottom: {
      height: 'auto',
      width: '70%',
    },
    customTable: {
      marginTop: '5%',
      borderCollapse: 'collapse',
      fontFamily: 'noto Sans JP',
    },
    span: {
      backgroundColor: '#2085f6',
      color: 'white',
    },
    spanStyle: {
      width: '2%',
      height: '8%',
      paddingBottom: '1%',
    },
    imgSize: {
      width: '1px',
      height: '1px',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        {/* <script src="css/assets/jss/jquery.mCustomScrollbar.css"></script> */}
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        {/* <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"></script> */}
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{ color: 'rgb(16, 181, 236)' }}>-</h4>
        <h3 style={style.margin}>
          {' '}
          自信と誇りを持って、価値創出を追求するSI企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　採用情報</dt>
                <dd>
                  <a href="/recruit01">インフラ</a>
                </dd>
                <dd>
                  <a href="/recruit02">無線通信</a>
                </dd>
                <dd>
                  <a href="/recruit03">開発</a>
                </dd>
                <dd>
                  <a href="/contact">応募登録</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <div>
                  <img src={img01} className="recruitImg01" />
                </div>
                <div
                  className="recruitTitle"
                  style={{ textAlign: 'left', marginTop: '2%' }}
                >
                  インフラエンジニア/経験浅めOK/CCNA・CCNP取得者多数/資格取得支援あり/年休120日/残業月10時間程度
                </div>

                <div className="recruitDetail" style={{ color: 'black' }}>
                <span className="recruitSpan01">
                    元々インフラエンジニアとして活躍していた代表。
                    <br />
                    エンジニアがストレスなく働くためには、 希望するプロジェクトに入れることだけでなく、
                    <br />
                    困った時に頼りにできる存在がいることなど、 本社や周りのメンバーの協力が大事になると考えています。
                  </span>
                  <br /> <br />
                  <span>
                    <img src={square} className="recruitSquare"></img>
                  </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    同じく「気持ちよく働きたい」と集まったメンバー
                  </span>
                  <br />
                  <span className="recruitSpan02">
                    エンジニアの多くは、過去に会社の体制に不満があった方や
                    困っていた方たちばかり。 同じ境遇のメンバーだからこそ、
                    チームで案件に入るときには信頼が置けます。
                  </span>
                  <br />
                  <br />
                  <span>
                    <img src={square} className="recruitSquare"></img>
                  </span>{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {' '}
                    スキルが停滞しない環境
                  </span>
                  <br />
                  <span className="recruitSpan02">
                    現状維持もストレスになると思います。 <br />
                    代表が過去の実績から多くの大手企業とつながりを持ち、
                    案件の多くは優良プロジェクト。
                    <br />
                    現在は多くがクラウド環境へのリプレイスや
                    仮想化に関するものです。
                    <br />
                    20年近くエンジニアをしている方でも
                    スキルアップできるという声があがっています！
                    <br />
                    今の環境に少しでも不安・ストレスを感じていましたら
                    ぜひ当社にお越しください。
                  </span>
                </div>

                <div className="recruitdiv">
                  <table className="recruitTable">
                    <tr>
                      <td className="recruittd1" align="center">
                        募集背景
                      </td>
                      <td className="recruittd2">
                        元インフラエンジニアの代表が設立した当社。大手企業・SIerとの取引も多数あり、
                        需要が高まるばかりです。経験浅めの方からベテランまでインフラエンジニアを幅広く募集しています。
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        仕事内容
                      </td>
                      <td className="recruittd2">
                        インフラエンジニアとしてサーバやネットワークなどのエンジニア業務（要件定義～保守）をお任せしていきます。
                        <br></br>
                        あなたのご経験やご希望に応じて相談の上、<br></br>
                        コンサルティングや提案から運用・保守までアサイン。
                        <br></br>
                        主にクラウド環境へのリプレイス案件が多いです。<br></br>
                        <br></br>
                        <span style={{backgroundImage:''}}>

                        </span>
                        <span style={{backgroundColor:'#0fd6db', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　開発環境・業務範囲　
                        </span>
                        <span>　</span>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold',borderRadius:'30px'}}>
                          　エンジニア限定取材　
                        </span>
                        <br></br>
                        <br></br>
                        〇流通小売業界大手ECサイト大規模改修プロジェクト
                        <br></br>
                        　概要：EC基盤をオンプレからAWSへ移行<br></br>
                        ・担当フェーズ<br></br>
                        　要件定義、設計、構築<br></br>
                        ・使用環境<br></br>
                        　AWS、Datadog、JP1/AJS3<br></br>
                        <br></br>
                        〇某ネット銀行システム更改対応<br></br>
                        　プライベートクラウドへの移行<br></br>
                        担当フェース：移行手順書の作成、移行リハーサル実施
                        <br></br>
                        プロジェクト体制：11名（当社より1名参画）<br></br>
                        使用環境F社クラウド<br></br>
                        <br></br>
                        〇某大手銀行ネットワークの更改<br></br>
                        概要：大手銀行の全国ネットワークを回線・機器の更改
                        <br></br>
                        担当フェーズ：移行作業の実施確認（順次移行）<br></br>
                        プロジェクト体制：15名程度（当社より2名参画）<br></br>
                        <br></br>
                        〇某金融機関業務系システムサーバー更改<br></br>
                        概要：EOLを迎える業務系サーバー10数台の更改<br></br>
                        担当フェーズ：移行計画～移行実行<br></br>
                        プロジェクト人数：約20名（当社より2名参画）<br></br>
                        使用環境：I社製サーバ<br></br>
                        <br></br>
                        ◎主な関連機器、環境等／UNIX（AIX）、Linux、Windows、VMware、AWS、Shell、JP1、Powershell、
                        Activedirectory、AJS3、Office365、 Azure DB： Oracle、MS
                        SQL（SQL Server）、DB2、PostgreSQL、ACCESS<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　CCNPやCCIEなどスキル高いエンジニアと働ける<br></br>
                        元インフラエンジニアの代表のもとには経験豊富なベテランエンジニアが多く集まっています！そのため、自社のエンジニアのほとんどはベンダ資格を複数所有。CCNPやCCIEなども保有するメンバーもおり、彼らとのチームアサインも可能です！Cisco資格をまだ持っていない方でしたら、入社時にCCNAを、保有されている方にもCCNPやCCIEなどワンランク上の資格を取得できるよう、会社のノウハウが詰まった資料や受験費負担でサポートしていきます。
                        <br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　注目　
                        </span>
                        　メンバーの誕生日にはプレゼントを<br></br>
                        社員同士のコミュニケーションを大事にする当社では、誕生日になるとプレゼントを持って、常駐先に幹部が訪問。もちろん誕生日でない何でもない日でも連絡をするなど、困ったときに頼りにできる関係作りを大切にしています。また、当社の営業はエンジニアたちを子どものように守る業界経験の長い父・母のような2名。一緒に働いて信頼のできる方ばかりだと思います。
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        応募資格
                      </td>
                      <td className="recruittd2">
                        ≪経験浅めの方もOK≫<br></br>
                        ◆インフラエンジニアの実務経験をお持ちの方<br></br>
                        （要件定義～運用・保守までスキルにあった案件があります）
                        <br></br>
                        ◆学歴不問<br></br>
                        スキルアップできる環境やフォロー体制に定評があります！
                        <br></br>
                        経験豊富な先輩社員とチームアサインも可能。<br></br>
                        女性エンジニアも複数名活躍中です！<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        雇用形態
                      </td>
                      <td className="recruittd2">正社員</td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        想定給与
                      </td>
                      <td className="recruittd2">
                        月給27万円～80万円<br></br>
                        ★多くの方が前職を上回る給与で入社しています！ご期待ください
                        <br></br>
                        <br></br>
                        ※経験や能力、実績、前職給与を考慮の上、面談で話し合って決定します
                        <br></br>
                        ※上記には固定残業代（6万1720円～/35時間分）が含まれます
                        <br></br>
                        ※35時間超過の場合は別途残業代支給（平均残業は月10時間です）
                        <br></br>
                        ※試用期間（3ヶ月）の給与や待遇に差異はございません
                        <br></br>
                        <br></br>
                        ＜別途各種手当あり＞<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　実際の給与例　
                        </span>
                        <br></br>
                        ≪年収例≫<br></br>
                        ◆年収710万円／45歳（入社9年）<br></br>
                        ◆年収580万円／37歳（入社5年）<br></br>
                        ◆年収415万円／31歳（入社3年）<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務時間
                      </td>
                      <td className="recruittd2">
                        原則9:00～18:00（実働8時間／休憩60分）<br></br>
                        ※プロジェクトにより異なる場合あり<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　残業について　
                        </span>
                        <br></br>
                        平均10時間程度 <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        勤務地
                      </td>
                      <td className="recruittd2">
                        テレワーク約50％◎東京23区内や神奈川県川崎市などのクライアント先
                        <br></br>
                        <br></br>
                        ※通勤時間やプロジェクト内容の希望を考慮して決定<br></br>
                        ※転居を伴う転勤なし<br></br>
                        <br></br>
                        ＜本社＞<br></br>
                        東京都千代田区紀尾井町4-1<br></br>
                        ニューオータニガーデンコート22F<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　交通・詳細　
                        </span>
                        <br></br>
                        ＜本社＞<br></br>
                        東京メトロ丸ノ内線(赤坂見附駅)より徒歩5分<br></br>
                        <br></br>
                        <span style={{backgroundColor:'#1590fb', color:'white',fontFamily:'noto Sans JP',fontWeight:'bold'}}>
                          　勤務地エリア　
                        </span>
                        <br></br>
                        東京都(新宿区)、神奈川県、埼玉県、千葉県<br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        休日休暇
                      </td>
                      <td className="recruittd2">
                        ＜年休120日＞<br></br> ◆完全週休2日制（土日）<br></br>{' '}
                        ◆祝日<br></br>
                        ◆年末年始休暇（12月29日～1月3日）<br></br>{' '}
                        ◆夏季休暇（3日間）<br></br>
                        ◆慶弔休暇<br></br>
                        ◆有給休暇（11～20日/年 ※勤続年数1年以上）<br></br>
                        ◆産前・産後休暇<br></br> ◆育児休暇<br></br> ◆介護休暇
                        <br></br>
                      </td>
                    </tr>
                    <tr>
                      <td className="recruittd1" align="center">
                        待遇・福利厚生
                      </td>
                      <td className="recruittd2">
                        ◆昇給年1回（4月）<br></br>
                        ◆各種社会保険完備（雇用・労災・健康・厚生年金）<br></br>
                        ◆資格取得支援（合格時に受験料・講座費用を全額支給）
                        <br></br>
                        ◆社員旅行（年1回/1泊2日）<br></br>
                        ◆BBQ<br></br>
                        ◆誕生日プレゼント<br></br>
                        ◆服装自由　※勤務先により例外あり<br></br>
                        ◆交通費全額支給<br></br>
                        ◆出張手当<br></br>
                        ◆役職手当<br></br>
                        ◆職能手当<br></br>
                      </td>
                    </tr>
                  </table>
                </div>
                <div className="work04Img03div" style={{marginBottom:'-1%'}} >
                    <img src={img02} className="work04Img03" />
                </div>
                <a href="/contact" >
                <img src={btn} className="workBtn"/>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Work01contents
